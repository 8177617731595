
import vue from 'vue';
import {mixins, HorizontalBar} from 'vue-chartjs';
import StatModel from '@/models/stat.model';
import QuestionModel from '@/models/question.model';
import WindowMessageModel from '@/models/functional/window-message.model';
import 'chartjs-plugin-datalabels';
import '@/pluginExtensions/chartjs-rectangle';
import Chart from 'chart.js';

export default vue.extend({
    extends: HorizontalBar,
    mixins: [mixins.reactiveData],
    name: 'BarChart',
    props: {
        question: [Object, QuestionModel],
        statisticData: [Object, StatModel],
    },
    data() {
        return {
            preComputedColor: '#808080FF',
            options: {} as Record<string, any>,
        };
    },
    methods: {
        transformData(): Record<string, any> {
            const data = {
                labels: [] as string[],
                datasets: [{
                    minBarLength: 1,
                    barPercentage: 1,
                    categoryPercentage: 0.75,
                    maxBarThickness: 180,
                    data: [] as number[],
                    backgroundColor: [] as string[],
                }],
            };

            const styleSheet = getComputedStyle(document.documentElement);

            if (this.question.type === 'emoji') {
                for (let c = 1; c <= this.question.options.emoticonsCount; c++) {
                    const emojiMap = {
                        1: '😡',
                        2: '🙁',
                        3: '😐',
                        4: '🙂',
                        5: '😊',
                    };
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    data.labels.push(emojiMap[c]);

                    const value = this.statisticData?.statistics[c] || 0;
                    data.datasets[0].data.push(value);
                    const color = styleSheet.getPropertyValue('--bar-background-color') || this.preComputedColor;
                    data.datasets[0].backgroundColor.push(color);
                }
                return data;
            }
            for (let c = 0; c < this.question.choices.length; c++) {
                const choice = this.question.choices[c];
                const label = choice.translations.content.replace(/<\/?[^>]+(>|$)/g, '');
                data.labels.push(label);

                const key = this.question.type === 'choice' ? choice.value : choice.uuid;
                const value = this.statisticData?.statistics[key] || 0;

                data.datasets[0].data.push(value);

                const color = styleSheet.getPropertyValue('--bar-background-color') || this.preComputedColor;
                data.datasets[0].backgroundColor.push(color);
            }

            return data;
        },
        transformAndUpdate() {
            this.$data.chartData = this.transformData();
            // eslint-disable-next-line no-underscore-dangle
            this.$data._chart.update();
        },
        styleMessageHandler($e: MessageEvent) {
            const msg = $e.data as WindowMessageModel;
            if (msg.type === 'color') {
                const colorEntry = msg.content;
                if ((colorEntry.property as string).startsWith('bar')) {
                    this.transformAndUpdate();
                } else if (colorEntry.property === 'body-color') {
                    Chart.defaults.global.defaultFontColor = colorEntry.value;
                    this.options.plugins.datalabels.color = colorEntry.value;
                    this.transformAndUpdate();
                }
            }
        },
    },
    watch: {
        statisticData() {
            this.transformAndUpdate();
        },
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.addPlugin({
            id: 'label-on-bar',
            beforeDraw(chart: any) {
                const {ctx} = chart.chart;
                const fontParams = ctx.font.split(' ') as string[];
                const font = fontParams.slice(1).join(' ');
                ctx.font = `12px ${font}`;
                ctx.fillStyle = Chart.defaults.global.defaultFontColor || '#000000';
                ctx.textAlign = 'left';
                chart!.config!.data.datasets.forEach((dataset: any) => {
                    for (let i = 0; i < dataset.data.length; i++) {
                        const metaData = dataset._meta[Object.keys(dataset._meta)[0]].data[i];
                        const model = metaData._model;
                        const {left} = metaData._xScale;
                        const {label} = model;
                        ctx.fillText(label, left, model.y - metaData._model.height * 0.625);
                    }
                });
            },
        });

        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {enabled: false},
            hover: {mode: null},
            legend: {display: false},
            layout: {
                padding: {
                    top: 16,
                    right: 56,
                },
            },
            scales: {
                xAxes: [{
                    ticks: {min: 0},
                    display: false,
                }],
                yAxes: [{
                    gridLines: {display: false},
                    ticks: {display: false},
                }],
            },
            plugins: {
                datalabels: {
                    display: true,
                    anchor: 'end',
                    align: 'end',
                    offset: 8,
                    color() {
                        return Chart.defaults.global?.defaultFontColor || '#000000';
                    },
                    font: {
                        size: 16,
                    },
                    formatter: (value: number, context: any) => {
                        if (!this.statisticData?.answered) return '0%';
                        const percentage = context.chart.options.percentageFixed
                            ? Math.round((value / context.dataset.data.reduce((acc: number, current: number) => acc + current)) * 100)
                            : Math.round((value / this.statisticData.answered) * 100);
                        return `${percentage}%`;
                    },

                },
            },
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.renderChart(this.$data.chartData, this.options);
        this.transformAndUpdate();
    },
    created() {
        window.addEventListener('message', this.styleMessageHandler);
    },
    destroyed() {
        window.removeEventListener('message', this.styleMessageHandler);
    },
});
