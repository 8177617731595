
























import vue from 'vue';
import StatModel from '@/models/stat.model';
import QuestionModel from '@/models/question.model';
import DoughnutChart from '@/components/Charts/DoughnutChart.vue';

export default vue.extend({
    name: 'NpsChart',
    components: {DoughnutChart},
    props: {
        question: [Object, QuestionModel],
        statisticData: [Object, StatModel],
    },
    data() {
        return {
            preComputedColors: {} as Record<number, string>,
            options: {} as Record<string, any>,
        };
    },
    computed: {
        detractorPerc(): string {
            const CoD = this.statisticData?.statistics['detractors'] as number;
            if (!this.statisticData?.answered) return '0';
            return ((CoD / this.statisticData.answered) * 100).toFixed(0);
        },
        passivesPerc(): string {
            const CoD = this.statisticData?.statistics['passives'] as number;
            if (!this.statisticData?.answered) return '0';
            return ((CoD / this.statisticData.answered) * 100).toFixed(0);
        },
        promotersPerc(): string {
            const CoD = this.statisticData?.statistics['promoters'] as number;
            if (!this.statisticData?.answered) return '0';
            return ((CoD / this.statisticData.answered) * 100).toFixed(0);
        },
    },
});
