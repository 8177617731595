
import ScriptModel from '@/models/script.model';
import StatModel from '@/models/stat.model';
import Stats from '@/views/Stats.vue';
import WindowMessageModel from '@/models/functional/window-message.model';
import Chart from 'chart.js';

// https://vue-chartjs.org/guide/#introduction
export default Stats.extend({
    name: 'Configurator',
    data() {
        return {
            activeIdx: 0,
            questionsOnly: true as boolean,
            stats: {
                    '6a448e3c-f53d-4feb-b37e-25f5229e72e7': {
                        instruction: '6a448e3c-f53d-4feb-b37e-25f5229e72e7',
                        considered: 12,
                        selected: 3,
                        statistics: {
                            1: 2,
                            4: 1,
                        },
                    },
                    '7e12294c-644f-4450-929f-a9bd559b4f69': {
                        instruction: '7e12294c-644f-4450-929f-a9bd559b4f69',
                        considered: 11,
                        selected: 5,
                        statistics: {
                            1: 1,
                            2: 2,
                            3: 1,
                            4: 1,
                        },
                    },
                    '60c0514d-758d-417c-9812-7ddb0f250e84': {
                        instruction: '60c0514d-758d-417c-9812-7ddb0f250e84',
                        considered: 3,
                        selected: 3,
                        statistics: {
                            'cef6cf1e-860c-47ca-b924-62185fa30331': 0,
                            '62478c20-96c5-481d-88a3-f49bb2fdb910': 0,
                            '88f648ca-cb8a-41fb-8235-5fd57a23d6f0': 1,
                            '241b845c-adc5-449b-83b2-cffaa6ed7a6f': 0,
                            'e06f75b8-3e50-44a7-8280-4e543ab4e344': 1,
                            'ad518b14-b9ad-4418-87cc-b91d43f1a089': 2,
                            '35fa8d95-e376-40a5-b6f9-f803a82f52fb': 1,
                            'e0d855ee-cbca-4dbe-b39f-b7178edb0b33': 0,
                        },
                    },
                    'a18dbaf2-ecea-47a0-9cd1-bd9562a269b7': {
                        instruction: 'a18dbaf2-ecea-47a0-9cd1-bd9562a269b7',
                        considered: 12,
                        selected: 9,
                        statistics: {
                            promoters: 4,
                            passives: 3,
                            detractors: 2,
                        },
                    },
                    '00f6f54c-ee0d-4b71-b963-ff650caf5b03': {
                        instruction: '00f6f54c-ee0d-4b71-b963-ff650caf5b03',
                        considered: 1,
                        selected: 1,
                        statistics: {
                            average: 6,
                        },
                    },
                } as Record<string, StatModel>,
            script: {
                    uuid: '432d57d3-c367-44a7-b3a5-8cd232d07ec2',
                    name: 'Question',
                    instructions: [
                        {
                            uuid: '60c0514d-758d-417c-9812-7ddb0f250e84',
                            type: 'question',
                            translations: [
                                {
                                    locale: 'en',
                                    content: 'Example of a multiple choice question with bar chart. (8 answer possibilities)',
                                },
                            ],
                            question: {
                                uuid: '2d395482-9420-4252-ac16-50ca7d0dd34d',
                                type: 'multiple',
                                options: {
                                    columns: 1,
                                    charttype: 'barchart',
                                    maximum: 4,
                                    randomize: true,
                                    fixated: [],
                                },
                                choices: [
                                    {
                                        uuid: 'cef6cf1e-860c-47ca-b924-62185fa30331',
                                        value: 1,
                                        translations: {
                                            locale: 'en',
                                            content: 'Answer one',
                                        },
                                    },
                                    {
                                        uuid: '241b845c-adc5-449b-83b2-cffaa6ed7a6f',
                                        value: 2,
                                        translations: {
                                            locale: 'en',
                                            content: 'Second answer',
                                        },
                                    },
                                    {
                                        uuid: '62478c20-96c5-481d-88a3-f49bb2fdb910',
                                        value: 3,
                                        translations: {
                                            locale: 'en',
                                            content: 'Answer three',
                                        },
                                    },
                                    {
                                        uuid: 'e06f75b8-3e50-44a7-8280-4e543ab4e344',
                                        value: 4,
                                        translations: {
                                            locale: 'en',
                                            content: 'Fourth answer',
                                        },
                                    },
                                    {
                                        uuid: '88f648ca-cb8a-41fb-8235-5fd57a23d6f0',
                                        value: 5,
                                        translations: {
                                            locale: 'en',
                                            content: 'Answer five',
                                        },
                                    },
                                    {
                                        uuid: '35fa8d95-e376-40a5-b6f9-f803a82f52fb',
                                        value: 6,
                                        translations: {
                                            locale: 'en',
                                            content: 'Sixth answer',
                                        },
                                    },
                                    {
                                        uuid: 'e0d855ee-cbca-4dbe-b39f-b7178edb0b33',
                                        value: 7,
                                        translations: {
                                            locale: 'en',
                                            content: 'Answer seven',
                                        },
                                    },
                                    {
                                        uuid: 'ad518b14-b9ad-4418-87cc-b91d43f1a089',
                                        value: 8,
                                        translations: {
                                            locale: 'en',
                                            content: 'Eight answer',
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            uuid: '7e12294c-644f-4450-929f-a9bd559b4f69',
                            type: 'question',
                            translations: [
                                {
                                    locale: 'en',
                                    content: 'Example of a single choice question with pie chart',
                                },
                            ],
                            question: {
                                uuid: '76c62161-a0e2-4e9b-82c7-a2e7baeb9984',
                                type: 'choice',
                                options: {
                                    columns: 1,
                                    randomize: false,
                                    charttype: 'piechart',
                                },
                                choices: [
                                    {
                                        uuid: 'b93e2ef3-dca9-4fb5-a4be-173c98c21e10',
                                        value: 1,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option 1',
                                        },
                                    },
                                    {
                                        uuid: '7855cc71-6567-400e-a643-cab3d92b3a63',
                                        value: 2,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option 2',
                                        },
                                    },
                                    {
                                        uuid: '03e415fd-24d1-4305-a030-8e3bec0c52b9',
                                        value: 3,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option 3',
                                        },
                                    },
                                    {
                                        uuid: '7f4342ab-43f5-4b82-9845-10665043c1cc',
                                        value: 4,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option 4',
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            uuid: '00f6f54c-ee0d-4b71-b963-ff650caf5b03',
                            type: 'question',
                            translations: [
                                {
                                    locale: 'en',
                                    content: 'Example of a numeric question with average score',
                                },
                            ],
                            question: {
                                uuid: '5f45b5c7-b29c-417d-bc60-44f2517d93fc',
                                type: 'numeric',
                                options: {
                                    charttype: 'average',
                                },
                                choices: [],
                            },
                        },
                        {
                            uuid: 'a18dbaf2-ecea-47a0-9cd1-bd9562a269b7',
                            type: 'question',
                            translations: [
                                {
                                    locale: 'en',
                                    content: 'Example of a NPS question (0-10)',
                                },
                            ],
                            question: {
                                uuid: '74d2ccd2-2984-4f31-919e-9476832309f4',
                                type: 'nps',
                                options: {
                                    min: 1,
                                    max: 10,
                                    charttype: 'npschart',
                                },
                                choices: [],
                            },
                        },
                        {
                            uuid: '7e12294c-644f-4450-929f-a9bd559b4f69',
                            type: 'question',
                            translations: [
                                {
                                    locale: 'en',
                                    content: 'Example of a multiple choice question with bar chart. (4 answer choices)',
                                },
                            ],
                            question: {
                                uuid: 'd9051a65-c322-4681-865a-b9c04e6ce504',
                                type: 'choice',
                                options: {
                                    columns: 1,
                                    charttype: 'barchart',
                                    randomize: true,
                                    fixated: [],
                                },
                                choices: [
                                    {
                                        uuid: '2f1a2078-d2dc-4c54-9d6c-dc2f5793c71c',
                                        value: 1,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option A',
                                        },
                                    },
                                    {
                                        uuid: '43cf65ac-7c93-47ba-9799-c2f2b509eebc',
                                        value: 2,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option B',
                                        },
                                    },
                                    {
                                        uuid: 'dde6f836-e86b-4c0b-8dde-862813fbd85c',
                                        value: 3,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option C',
                                        },
                                    },
                                    {
                                        uuid: 'f6173d61-3def-4566-b1de-5f1a9d43ca7e',
                                        value: 4,
                                        translations: {
                                            locale: 'en',
                                            content: 'Option D',
                                        },
                                    },
                                ],
                            },
                        },
                    ],
                } as ScriptModel,
        };
    },
    computed: {
        currentInstruction() {
            if (this.script === null) return null;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            return this.script.instructions[this.activeIdx];
        },
        currentParamContainer() {
            if (this.script === null || this.currentInstruction!.question === undefined) return undefined;
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            return this.currentInstruction!.question;
        },
        currentStat(): StatModel | null {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            if (this.stats === null || this.stats.length === 0) return undefined;
            return this.stats[this.currentInstruction!.uuid] as StatModel || undefined;
        },
        currentPopulation() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            const current = this.currentStat;
            if (current == undefined) return 0;
            return current.considered;
        },
        currentSample() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore TS2339
            const current = this.currentStat;
            if (current == undefined) return 0;
            return current.selected;
        },
    },
    methods: {
        styleMessageHandler($e: MessageEvent) {
            const msg = $e.data as WindowMessageModel;
            if (msg.type === 'color') {
                const colorEntry = msg.content;
                document.documentElement.style.setProperty(`--${colorEntry.property}`, colorEntry.value);
            }
        },
        getScriptOutline(community: string, script: string) { /* method disabled */
        },
        getStats() { /* method disabled */
        },
    },
    created() {
        window.addEventListener('message', this.styleMessageHandler);
    },
    mounted() {
        this.questionsOnly = this.$route.query.all !== 'true';
        // const community = this.$route.params.community as string;
    },
    destroyed() {
        window.removeEventListener('message', this.styleMessageHandler);
    },
});
