import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Stats from '@/views/Stats.vue';
import Configurator from '@/views/Configurator.vue';
import Preview from '@/views/Preview.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/:community/configure',
        name: 'Configurator',
        component: Configurator,
    },
    {
        path: '/:community/preview',
        name: 'Preview',
        component: Preview,
    },
    {
        path: '/:community/:script',
        name: 'Stats',
        component: Stats,
        /* query params:
            all = [true, false]
         */
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
