export type ApiError = Error & {request: Request; response: Response};
export type RequestBody = {[key: string]: any};
export default class AbbiAPI {
    protected static url: string | null = null;

    protected static lastError: ApiError;

    public static setBase(url: string) {
        if (AbbiAPI.url === null) {
            AbbiAPI.url = url;
        }
    }

    public static getLastError() {
        return AbbiAPI.lastError;
    }

    public static getStyle(communitySlug: string): Promise<any> {
        return AbbiAPI.get(`${AbbiAPI.url}/${communitySlug}/style/stats`);
    }

    public static GetScriptOutline(communitySlug: string, scriptUuid: string) {
        return AbbiAPI.get(`${AbbiAPI.url}/${communitySlug}/${scriptUuid}`);
    }

    public static GetStatsForScript(communitySlug: string, scriptUuid: string) {
        return AbbiAPI.get(`${AbbiAPI.url}/${communitySlug}/${scriptUuid}/stats`);
    }

    private static performRequest(
        method: string,
        url: string,
        body: RequestBody|null = null,
    ): Promise<Record<string, any>|undefined> {
        const headers = new Headers();
        headers.append('Accept', 'application/json, text/plain, */*');
        headers.append('Content-Type', 'application/json');

        // noinspection TypeScriptValidateTypes
        const request = new Request(
            url,
            {
                method,
                headers,
                body: body === null ? undefined : JSON.stringify(body),
            },
        );

        return fetch(request).then((response) => {
            if (!response.ok) {
                this.lastError = this.createError(request, response);
                throw this.lastError;
            }

            return response.json();
        });
    }

    protected static createError(request: Request, response: Response): ApiError {
        return Object.assign(
            new Error(`HTTP error! Status: ${response.status}`),
            {request, response},
        );
    }

    protected static delete(url: string, body: RequestBody): Promise<Record<string, any>|undefined> {
        return this.performRequest('POST', url, body);
    }

    protected static get(url: string): Promise<Record<string, any>|undefined> {
        return this.performRequest('GET', url);
    }

    protected static patch(url: string, body: RequestBody): Promise<Record<string, any>|undefined> {
        return this.performRequest('PATCH', url, body);
    }

    protected static post(url: string, body: RequestBody): Promise<Record<string, any>|undefined> {
        return this.performRequest('POST', url, body);
    }

    protected static put(url: string, body: RequestBody): Promise<Record<string, any>|undefined> {
        return this.performRequest('PUT', url, body);
    }
}
