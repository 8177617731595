const environment = process.env.VUE_APP_ABBI_ENVIRONMENT || 'local';

const objectStorage = {
    local: 'http://localhost:1024/abbi-insights-local',
    staging: 'https://abbi-insights-staging.cdn.cloudbear.nl',
    production: 'https://abbi-insights.cdn.cloudbear.nl',
};

export default {
    apiBase: (process.env.VUE_APP_ABBI_API_BASE || `${window.location.protocol}//${window.location.hostname}:3000`),
    objectStorage,
};
