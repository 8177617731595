
import vue from 'vue';
import {mixins, Pie} from 'vue-chartjs';
import StatModel from '@/models/stat.model';
import QuestionModel from '@/models/question.model';
import WindowMessageModel from '@/models/functional/window-message.model';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';

export default vue.extend({
    extends: Pie,
    mixins: [mixins.reactiveData],
    components: {ChartJSPluginDatalabels},
    name: 'PieChart',
    props: {
        question: [Object, QuestionModel],
        statisticData: [Object, StatModel],
    },
    data() {
        return {
            preComputedColors: {} as Record<number, string>,
            options: {} as Record<string, any>,
        };
    },
    methods: {
        transformData(): Record<string, any> {
            const data = {
                labels: [] as string[],
                datasets: [{
                    data: [] as number[],
                    backgroundColor: [] as string[],
                }],
            };

            const styleSheet = getComputedStyle(document.documentElement);
            if (['choice', 'multiple'].includes(this.question.type)) {
                for (let c = 0; c < this.question.choices.length; c++) {
                    const choice = this.question.choices[c];
                    data.labels.push(choice.translations.content.replace(/<\/?[^>]+(>|$)/g, ''));
                    data.datasets[0].data.push(this.statisticData?.statistics[choice.value] || 0);

                    let color = styleSheet.getPropertyValue(`--pie${c + 1}-background-color`) || this.preComputedColors[c];
                    if (color === '' || color === undefined) {
                        const r = Math.floor(Math.random() * 256);
                        const g = Math.floor(Math.random() * 256);
                        const b = Math.floor(Math.random() * 256);
                        color = `rgb(${r}, ${g}, ${b})`;
                        this.preComputedColors[c] = color;
                    }
                    data.datasets[0].backgroundColor.push(color);
                }
            }

            if (this.question.type === 'file' && this.statisticData) {
                Object.entries(this.statisticData.statistics).forEach(([key, value], index) => {
                    data.labels.push(key);
                    /* eslint-disable @typescript-eslint/ban-ts-ignore */
                    // @ts-ignore
                    data.datasets[0].data.push(value);
                    let color = styleSheet.getPropertyValue(`--pie${index + 1}-background-color`) || this.preComputedColors[index];
                    if (color === '' || color === undefined) {
                        const r = Math.floor(Math.random() * 256);
                        const g = Math.floor(Math.random() * 256);
                        const b = Math.floor(Math.random() * 256);
                        color = `rgb(${r}, ${g}, ${b})`;
                        this.preComputedColors[index] = color;
                    }
                    data.datasets[0].backgroundColor.push(color);
                });
            }
            return data;
        },
        transformAndUpdate() {
            this.$data.chartData = this.transformData();
            // eslint-disable-next-line no-underscore-dangle
            this.$data._chart.update();
        },
        styleMessageHandler($e: MessageEvent) {
            const msg = $e.data as WindowMessageModel;
            if (msg.type === 'color') {
                const colorEntry = msg.content;
                if ((colorEntry.property as string).startsWith('pie')) {
                    this.transformAndUpdate();
                }
            }
        },
    },
    watch: {
        statisticData() {
            this.transformAndUpdate();
        },
    },
    mounted() {
        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                position: 'bottom',
            },
            plugins: {
                datalabels: {
                    //                    anchor: 'end',
                    // clamp: true,
                    anchor: 'end',
                    align: 'start',
                    color(context: any) {
                        const index = context.dataIndex;
                        const background = context.dataset.backgroundColor[index];

                        let r;
                        let g;
                        let b;
                        let a;
                        if (background.includes('#')) {
                            const plaincolor = background.substring(1);
                            r = Number.parseInt(plaincolor.substring(0, 2), 16) / 255;
                            g = Number.parseInt(plaincolor.substring(2, 4), 16) / 255;
                            b = Number.parseInt(plaincolor.substring(4, 6), 16) / 255;
                            a = Number.parseInt(plaincolor.substring(6), 16) / 255;
                        } else {
                            const value = background.substr(4, background.length - 5);
                            const [_r, _g, _b] = value.split(',');
                            r = _r / 255;
                            g = _g / 255;
                            b = _b / 255;
                            a = 1;
                        }

                        const L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                        return (L > 0.5 || a < 0.25) ? 'black' : 'white';
                    },
                    clamp: true,
                    offset: 10,
                    display: true,
                    font: {
                        weight: 'bold',
                        size: 16,
                    },
                    formatter(value: number, context: any) {
                        return value > 0 ? `${((value / (context.dataset?.data?.reduce((sum: number, current: number) => sum + current) || 1)) * 100).toFixed(0)}%` : '';
                    },
                },
            },
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.renderChart(this.$data.chartData, this.options);
        this.transformAndUpdate();
    },
    created() {
        window.addEventListener('message', this.styleMessageHandler);
    },
    destroyed() {
        window.removeEventListener('message', this.styleMessageHandler);
    },
});
