import AbbiAPI from '@/apis/abbi.api';
import settings from '@/settings';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/icons';

AbbiAPI.setBase(settings.apiBase);

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
