









import vue from 'vue';
import Loader from '@/views/Loader.vue';
import CommunityService from '@/services/community.service';

export default vue.extend({
    name: 'App',
    components: {Loader},
    methods: {
        async prepareCommunity() {
            const {community} = this.$router.currentRoute.params;

            function handleProperty(property: string, value: string) {
                if (property === 'header_background-color') {
                    return;
                }
                if (property.endsWith('background-image')) {
                    return;
                }

                const {style} = document.documentElement;

                style.setProperty(
                    `--${property.replaceAll('_', '-')}`,
                    value,
                );
            }

            if (community !== undefined) {
                this.styling = await CommunityService.GetStylingForCommunity();
                if (this.styling !== null) {
                    if (this.styling.community !== undefined && this.styling.community['custom-stylesheet'] !== undefined) {
                        const stylesheet = document.createElement('link') as HTMLLinkElement;
                        stylesheet.href = this.styling?.community['custom-stylesheet'];
                        stylesheet.rel = 'stylesheet';
                        stylesheet.type = 'text/css';
                        document.head.appendChild(stylesheet);
                    }

                    Object.keys(this.styling?.web || {}).forEach((property: string) => {
                        if (property === 'header_background-color') {
                            return;
                        }
                        handleProperty(property, this.styling?.web?.[property]);
                    });
                }
            }
        },
        getStyle(property: string): string {
            const {style} = document.documentElement;
            return style.getPropertyValue(property);
        },
        setStyle(property: string, value: string): void {
            const {style} = document.documentElement;
            style.setProperty(property, value);
        },
    },
    data() {
        return {
            styling: null as Record<string, any> | null,
        };
    },
    async mounted() {
        await this.prepareCommunity();
    },
    computed: {
        styled(): boolean {
            return this.styling !== null;
        },
        fontSourceGoogleEnabled(): boolean {
            return this.styling?.web?.['font_source_google'] === '1' ?? false;
        },
        fontFamily(): string {
            if (this.styling === null) return '';

            const { web, community } = this.styling;

            if (web === null && community === null) return '';

            const communityFontFamily = community?.['font-fam']?.replace(/^font-family: /, '');

            const googleFontFamily = this.getStyle('--body-google-font-family');
            if (this.fontSourceGoogleEnabled && googleFontFamily) {
                const fontWeight = this.getStyle('--body-font-weight');
                CommunityService.loadGoogleFontFromFamilyNameAndWeight(googleFontFamily, fontWeight);

                return googleFontFamily;
            }

            const fontFamily = this.getStyle('--body-font-family');

            return fontFamily || communityFontFamily || 'Quicksand Variable';
        },
        fontFileUrl(): string {
            if (this.styling === null) return '';

            if (this.fontSourceGoogleEnabled) return '';

            const { web, community } = this.styling;

            if (web === null && community === null) return '';

            const communityFontUrl = community?.['font-url'];

            return web?.['body_font-file'] || communityFontUrl || '';
        },
    },
    watch: {
        fontFileUrl(url: string) {
            if (url === '') return;

            CommunityService.addFontFaceFromNameAndUrl(this.fontFamily, url);
        },
    },
});
