<template>
    <div id="abbiLoader">
        <div class="spinner">
            <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="3" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
};
</script>

<style lang="scss">
#abbiLoader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 6660;

    background-color: white;

    display: grid;
    place-items: center;

    $offset: 235;
    $duration: 2s;

    .spinner {
        width: 25vw;
        height: 25vw;

        max-width: 256px;
        max-height: 256px;

        animation: rotator $duration linear infinite;

        @keyframes rotator {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: $offset;
        transform-origin: center;
        animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
        stroke: black;

        @keyframes colors {
            0% {
                stroke: #4285F4;
            }
            25% {
                stroke: #DE3E35;
            }
            50% {
                stroke: #F7C223;
            }
            75% {
                stroke: #1B9A59;
            }
            100% {
                stroke: #4285F4;
            }
        }

        @keyframes dash {
            0% {
                stroke-dashoffset: $offset;
                stroke-width: 6px;
                transform: rotate(0deg);
            }
            50% {
                stroke-dashoffset: $offset / 2;
                stroke-width: 3px;
                transform: rotate(135deg);
            }
            100% {
                stroke-dashoffset: $offset;
                stroke-width: 6px;
                transform: rotate(360deg);
            }
        }
    }
}
</style>
