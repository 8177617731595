import ChoiceModel from '@/models/choice.model';

export default class QuestionModel {
    public uuid = '';

    public type = '';

    public options?: {[key: string]: any} = undefined;

    public choices?: ChoiceModel[] = undefined;
}
