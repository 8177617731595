
import vue from 'vue';
import {mixins, Doughnut} from 'vue-chartjs';
import StatModel from '@/models/stat.model';

export default vue.extend({
    extends: Doughnut,
    mixins: [mixins.reactiveData],
    name: 'DoughnutChart',
    props: {
        statisticData: [Object, StatModel],
    },
    data() {
        return {
            options: {} as Record<string, any>,
        };
    },
    methods: {
        transformData(): Record<string, any> {
            const data = {
                labels: ['promoters', 'passives', 'detractors'],
                datasets: [{
                    minBarLength: 1,
                    data: [] as number[],
                    priorities: [2, 1, 0],
                    borderWidth: [5, 5, 5],
                    hoverBorderWidth: [2, 2, 2],
                    backgroundColor: ['#72985a', '#ca8f67', '#c34d4d'],
                }],
            };

            for (let c = 0; c < data.labels.length; c++) {
                data.datasets[0].data.push(this.statisticData?.statistics[data.labels[c]] || 0);
            }

            return data;
        },
        transformAndUpdate() {
            this.$data.chartData = this.transformData();
            // eslint-disable-next-line no-underscore-dangle
            this.$data._chart.update();
        },
    },
    watch: {
        statisticData() {
            this.transformAndUpdate();
        },
    },
    mounted() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.addPlugin({
            id: 'doughnut-label',
            beforeDraw(chart: any) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const {width} = chart.chart;
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const {height} = chart.chart;
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const {ctx} = chart.chart;

                ctx.restore();
                const fontSize = (height / 114).toFixed(2);
                ctx.font = `${fontSize}em sans-serif`;
                ctx.textBaseline = 'middle';

                const {data} = chart!.config!.data!.datasets![0]!;
                const detractors = data[0];
                const passives = data[1];
                const promoters = data[2];
                const total = detractors + passives + promoters;
                const nps = (detractors / total) - (promoters / total);
                const text = (nps > 0 ? '+' : '') + String((nps * 100).toFixed(0));

                const textX = Math.round((width - ctx.measureText(text).width) / 2);
                const textY = height / 2;

                ctx.fillText(text, textX, textY);
                ctx.save();
            },
        });

        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.renderChart(this.$data.chartData, this.options);
        this.transformAndUpdate();
    },
});
