






















import vue from 'vue';
import StatModel from '@/models/stat.model';
import {FaRating} from 'vue-rate-it';
import Star from 'vue-rate-it/glyphs/star';

function getStyleProperty(key: string, fallback: string = ''): string {
    const sanitizedKey = key.replaceAll('_', '-');
    const documentStyle = getComputedStyle(document.documentElement);
    return documentStyle.getPropertyValue(`--${sanitizedKey}`) || fallback;
}

export default vue.extend({
    name: 'AverageChart',
    components: {FaRating},
    props: {
        statisticData: [Object, StatModel],
        presentations: Array,
    },
    created() {
        this.glyph = Star;
    },
    data() {
        return {
            glyph: '',
        };
    },
    computed: {
        statistic(): string {
            if (!this.statisticData) return '';

            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(Number(this.statisticData.statistics.average))) {
                const currency = this.statisticData.statistics.average.charAt(0);
                const value = Number(this.statisticData.statistics.average.replace(currency, '')).toFixed(1);
                return `${currency} ${value}`;
            }
            return Number(this.statisticData.statistics.average).toFixed(1);
        },
        inactiveColor: () => getStyleProperty('star-rating-inactive-color', '#D8D8D8FF'),
        activeColor: () => getStyleProperty('star-rating-active-color', '#FED055FF'),
        borderColor: () => getStyleProperty('star-rating-border-color', '#000000FF'),
        borderWidth: () => parseInt(getStyleProperty('star-rating-border-width', '2'), 10) ?? 2,
        rating(): number|null {
            if (!this.statistic) return null;

            return parseFloat(this.statistic);
        },
    },
});
